//export const zoom = 14;

export const containerStyle = {
  width: "100%",
  height: "100%",
};

export const mapStyle = [
  {
    featureType: "poi.business",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "labels.text",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "labels.icon",
    stylers: [
      {
        color: "#8f8f8f",
      },
    ],
  },
  {
    featureType: "transit.station",
    elementType: "labels.icon",
    stylers: [
      {
        color: "#8f8f8f",
      },
    ],
  },
  {
    featureType: "poi.attraction",
    elementType: "labels.icon",
    stylers: [
      {
        color: "#8f8f8f",
      },
    ],
  },
  {
    featureType: "poi.place_of_worship",
    elementType: "labels.icon",
    stylers: [
      {
        color: "#8f8f8f",
      },
    ],
  },
  {
    featureType: "poi.sports_complex",
    elementType: "labels.icon",
    stylers: [
      {
        color: "#8f8f8f",
      },
    ],
  },
  {
    featureType: "poi.medical",
    elementType: "labels.icon",
    stylers: [
      {
        color: "#8f8f8f",
      },
    ],
  },
];

export const calculateScheduleOld = (newDate, openHours) => {
  const fullDateString = `${newDate.getFullYear()}-${
    newDate.getMonth() + 1
  }-${newDate.getDate()}`;

  let isOpened = false;
  let closedOnMorning = false;

  switch (newDate.getDay()) {
    case 0:
      if (isNotNill(openHours.sunday)) {
        const hoursSplitted = openHours.sunday.split("-");
        const fromDate = new Date(`${fullDateString} ${hoursSplitted[0]}:00`);
        const toDate = new Date(`${fullDateString} ${hoursSplitted[1]}:00`);

        if (newDate >= fromDate && newDate < toDate) {
          isOpened = true;
        } else if (newDate < fromDate) {
          closedOnMorning = true;
        }
      }
      break;
    case 1:
      if (isNotNill(openHours.monday)) {
        const hoursSplitted = openHours.monday.split("-");
        const fromDate = new Date(`${fullDateString} ${hoursSplitted[0]}:00`);
        const toDate = new Date(`${fullDateString} ${hoursSplitted[1]}:00`);

        if (newDate >= fromDate && newDate < toDate) {
          isOpened = true;
        } else if (newDate < fromDate) {
          closedOnMorning = true;
        }
      }
      break;
    case 2:
      if (isNotNill(openHours.tuesday)) {
        const hoursSplitted = openHours.tuesday.split("-");
        const fromDate = new Date(`${fullDateString} ${hoursSplitted[0]}:00`);
        const toDate = new Date(`${fullDateString} ${hoursSplitted[1]}:00`);

        if (newDate >= fromDate && newDate < toDate) {
          isOpened = true;
        } else if (newDate < fromDate) {
          closedOnMorning = true;
        }
      }
      break;
    case 3:
      if (isNotNill(openHours.wednesday)) {
        const hoursSplitted = openHours.wednesday.split("-");
        const fromDate = new Date(`${fullDateString} ${hoursSplitted[0]}:00`);
        const toDate = new Date(`${fullDateString} ${hoursSplitted[1]}:00`);

        if (newDate >= fromDate && newDate < toDate) {
          isOpened = true;
        } else if (newDate < fromDate) {
          closedOnMorning = true;
        }
      }
      break;
    case 4:
      if (isNotNill(openHours.thursday)) {
        const hoursSplitted = openHours.thursday.split("-");
        const fromDate = new Date(`${fullDateString} ${hoursSplitted[0]}:00`);
        const toDate = new Date(`${fullDateString} ${hoursSplitted[1]}:00`);

        if (newDate >= fromDate && newDate < toDate) {
          isOpened = true;
        } else if (newDate < fromDate) {
          closedOnMorning = true;
        }
      }
      break;
    case 5:
      if (isNotNill(openHours.friday)) {
        const hoursSplitted = openHours.friday.split("-");
        const fromDate = new Date(`${fullDateString} ${hoursSplitted[0]}:00`);
        const toDate = new Date(`${fullDateString} ${hoursSplitted[1]}:00`);

        if (newDate >= fromDate && newDate < toDate) {
          isOpened = true;
        } else if (newDate < fromDate) {
          closedOnMorning = true;
        }
      }
      break;
    case 6:
      if (openHours.saturday) {
        const hoursSplitted = openHours.saturday.split("-");
        const fromDate = new Date(`${fullDateString} ${hoursSplitted[0]}:00`);
        const toDate = new Date(`${fullDateString} ${hoursSplitted[1]}:00`);

        if (newDate >= fromDate && newDate < toDate) {
          isOpened = true;
        } else if (newDate < fromDate) {
          closedOnMorning = true;
        }
      }
      break;
    default:
      break;
  }

  let openCloseAt = "";
  let indexDay = newDate.getDay();

  if (isOpened) {
    switch (indexDay) {
      case 0:
        openCloseAt = isNotNill(openHours.sunday)
          ? `Cierra a las ${openHours.sunday.split("-")[1]} hrs.`
          : "no data";
        break;

      case 1:
        openCloseAt = isNotNill(openHours.monday)
          ? `Cierra a las ${openHours.monday.split("-")[1]} hrs.`
          : "no data";
        break;

      case 2:
        openCloseAt = isNotNill(openHours.tuesday)
          ? `Cierra a las ${openHours.tuesday.split("-")[1]} hrs.`
          : "no data";
        break;

      case 3:
        openCloseAt = isNotNill(openHours.wednesday)
          ? `Cierra a las ${openHours.wednesday.split("-")[1]} hrs.`
          : "no data";
        break;

      case 4:
        openCloseAt = isNotNill(openHours.thursday)
          ? `Cierra a las ${openHours.thursday.split("-")[1]} hrs.`
          : "no data";
        break;

      case 5:
        openCloseAt = isNotNill(openHours.friday)
          ? `Cierra a las ${openHours.friday.split("-")[1]} hrs.`
          : "no data";
        break;

      case 6:
        openCloseAt = isNotNill(openHours.saturday)
          ? `Cierra a las ${openHours.saturday.split("-")[1]} hrs.`
          : "no data";
        break;

      default:
        break;
    }
  } else {
    if (!closedOnMorning) {
      indexDay++;
    }

    var counterExit = 0;
    while (openCloseAt === "") {
      if (indexDay > 6) indexDay = 0;

      switch (indexDay) {
        case 0:
          if (!isNotNill(openHours.sunday)) {
            indexDay++;
          } else {
            openCloseAt = `Abre ${!closedOnMorning ? `el Domingo` : ``} a las ${
              openHours.sunday.split("-")[0]
            } hrs.`;
          }
          closedOnMorning = false;
          break;
        case 1:
          if (!isNotNill(openHours.monday)) {
            indexDay++;
          } else {
            openCloseAt = `Abre ${!closedOnMorning ? `el Lunes` : ``} a las ${
              openHours.monday.split("-")[0]
            } hrs.`;
          }
          closedOnMorning = false;
          break;
        case 2:
          if (!isNotNill(openHours.tuesday)) {
            indexDay++;
          } else {
            openCloseAt = `Abre ${!closedOnMorning ? `el Martes` : ``} a las ${
              openHours.tuesday.split("-")[0]
            } hrs.`;
          }
          closedOnMorning = false;
          break;
        case 3:
          if (!isNotNill(openHours.wednesday)) {
            indexDay++;
          } else {
            openCloseAt = `Abre ${
              !closedOnMorning ? `el Miércoles` : ``
            } a las ${openHours.wednesday.split("-")[0]} hrs.`;
          }
          closedOnMorning = false;
          break;
        case 4:
          if (!isNotNill(openHours.thursday)) {
            indexDay++;
          } else {
            openCloseAt = `Abre ${!closedOnMorning ? `el Jueves` : ``} a las ${
              openHours.thursday.split("-")[0]
            } hrs.`;
          }
          closedOnMorning = false;
          break;
        case 5:
          if (!isNotNill(openHours.friday)) {
            indexDay++;
          } else {
            openCloseAt = `Abre ${!closedOnMorning ? `el Viernes` : ``} a las ${
              openHours.friday.split("-")[0]
            } hrs.`;
          }
          closedOnMorning = false;
          break;
        case 6:
          if (!isNotNill(openHours.saturday)) {
            indexDay++;
          } else {
            openCloseAt = `Abre ${!closedOnMorning ? `el Sábado` : ``} a las ${
              openHours.saturday.split("-")[0]
            } hrs.`;
          }
          closedOnMorning = false;
          break;
        default:
          break;
      }

      counterExit++;
      if (counterExit > 8) openCloseAt = "no data";
    }
  }
  return (isOpened ? `Abierto - ` : `Cerrado - `) + openCloseAt;
};
export const calculateSchedule = (newDate, attentions) => {
  if(attentions.length === 1) return
  if (attentions === undefined) return;
  const fullDateString = `${newDate.getFullYear()}-${
    newDate.getMonth() + 1
  }-${newDate.getDate()}`;

  let isOpened = false;
  let closedOnMorning = false;

  switch (newDate.getDay()) {
    case 0:
      if (isNotNill(attentions[6])) {
        const fromDate = new Date(
          `${fullDateString} ${attentions[6].startTime}`
        );
        const toDate = new Date(`${fullDateString} ${attentions[6].endTime}`);

        if (newDate >= fromDate && newDate < toDate) {
          isOpened = true;
        } else if (newDate < fromDate) {
          closedOnMorning = true;
        }
      }
      break;
    case 1:
      if (isNotNill(attentions[0])) {
        const fromDate = new Date(
          `${fullDateString} ${attentions[1].startTime}`
        );
        const toDate = new Date(`${fullDateString} ${attentions[1].endTime}`);

        if (newDate >= fromDate && newDate < toDate) {
          isOpened = true;
        } else if (newDate < fromDate) {
          closedOnMorning = true;
        }
      }
      break;
    case 2:
      if (isNotNill(attentions[1])) {
        const fromDate = new Date(
          `${fullDateString} ${attentions[1].startTime}`
        );
        const toDate = new Date(`${fullDateString} ${attentions[1].endTime}`);

        if (newDate >= fromDate && newDate < toDate) {
          isOpened = true;
        } else if (newDate < fromDate) {
          closedOnMorning = true;
        }
      }
      break;
    case 3:
      if (isNotNill(attentions[2])) {
        const fromDate = new Date(
          `${fullDateString} ${attentions[2].startTime}`
        );
        const toDate = new Date(`${fullDateString} ${attentions[2].endTime}`);

        if (newDate >= fromDate && newDate < toDate) {
          isOpened = true;
        } else if (newDate < fromDate) {
          closedOnMorning = true;
        }
      }
      break;
    case 4:
      if (isNotNill(attentions[3])) {
        const fromDate = new Date(
          `${fullDateString} ${attentions[3].startTime}`
        );
        const toDate = new Date(`${fullDateString} ${attentions[3].endTime}`);

        if (newDate >= fromDate && newDate < toDate) {
          isOpened = true;
        } else if (newDate < fromDate) {
          closedOnMorning = true;
        }
      }
      break;
    case 5:
      if (isNotNill(attentions[4])) {
        const fromDate = new Date(
          `${fullDateString} ${attentions[4].startTime}`
        );
        const toDate = new Date(`${fullDateString} ${attentions[4].endTime}`);

        if (newDate >= fromDate && newDate < toDate) {
          isOpened = true;
        } else if (newDate < fromDate) {
          closedOnMorning = true;
        }
      }
      break;
    case 6:
      if (isNotNill(attentions[5])) {
        const fromDate = new Date(
          `${fullDateString} ${attentions[5].startTime}`
        );
        const toDate = new Date(`${fullDateString} ${attentions[5].endTime}`);

        if (newDate >= fromDate && newDate < toDate) {
          isOpened = true;
        } else if (newDate < fromDate) {
          closedOnMorning = true;
        }
      }
      break;
    default:
      break;
  }

  let openCloseAt = "";
  let indexDay = newDate.getDay();

  if (isOpened) {
    switch (indexDay) {
      case 0:
        openCloseAt = isNotNill(attentions[6])
          ? `Cierra a las ${attentions[6].endTime} hrs.`
          : "no data";
        break;

      case 1:
        openCloseAt = isNotNill(attentions[0])
          ? `Cierra a las ${attentions[0].endTime} hrs.`
          : "no data";
        break;

      case 2:
        openCloseAt = isNotNill(attentions[1])
          ? `Cierra a las ${attentions[1].endTime} hrs.`
          : "no data";
        break;

      case 3:
        openCloseAt = isNotNill(attentions[2])
          ? `Cierra a las ${attentions[2].endTime} hrs.`
          : "no data";
        break;

      case 4:
        openCloseAt = isNotNill(attentions[3])
          ? `Cierra a las ${attentions[3].endTime} hrs.`
          : "no data";
        break;

      case 5:
        openCloseAt = isNotNill(attentions[4])
          ? `Cierra a las ${attentions[4].endTime} hrs.`
          : "no data";
        break;

      case 6:
        openCloseAt = isNotNill(attentions[5])
          ? `Cierra a las ${attentions[5].endTime} hrs.`
          : "no data";
        break;

      default:
        break;
    }
  } else {
    if (!closedOnMorning) {
      indexDay++;
    }

    var counterExit = 0;
    while (openCloseAt === "") {
      if (indexDay > 6) indexDay = 0;

      switch (indexDay) {
        case 0:
          if (!isNotNill(attentions[6])) {
            indexDay++;
          } else {
            openCloseAt = `Abre ${!closedOnMorning ? `el Domingo` : ``} a las ${
              attentions[6].startTime
            } hrs.`;
          }
          closedOnMorning = false;
          break;
        case 1:
          if (!isNotNill(attentions[0])) {
            indexDay++;
          } else {
            openCloseAt = `Abre ${!closedOnMorning ? `el Lunes` : ``} a las ${
              attentions[0].startTime
            } hrs.`;
          }
          closedOnMorning = false;
          break;
        case 2:
          if (!isNotNill(attentions[1])) {
            indexDay++;
          } else {
            openCloseAt = `Abre ${!closedOnMorning ? `el Martes` : ``} a las ${
              attentions[1].startTime
            } hrs.`;
          }
          closedOnMorning = false;
          break;
        case 3:
          if (!isNotNill(attentions[2])) {
            indexDay++;
          } else {
            openCloseAt = `Abre ${
              !closedOnMorning ? `el Miércoles` : ``
            } a las ${attentions[2].startTime} hrs.`;
          }
          closedOnMorning = false;
          break;
        case 4:
          if (!isNotNill(attentions[3])) {
            indexDay++;
          } else {
            openCloseAt = `Abre ${!closedOnMorning ? `el Jueves` : ``} a las ${
              attentions[3].startTime
            } hrs.`;
          }
          closedOnMorning = false;
          break;
        case 5:
          if (!isNotNill(attentions[4])) {
            indexDay++;
          } else {
            openCloseAt = `Abre ${!closedOnMorning ? `el Viernes` : ``} a las ${
              attentions[4].startTime
            } hrs.`;
          }
          closedOnMorning = false;
          break;
        case 6:
          if (!isNotNill(attentions[5])) {
            indexDay++;
          } else {
            openCloseAt = `Abre ${!closedOnMorning ? `el Sabado` : ``} a las ${
              attentions[5].startTime
            } hrs.`;
          }
          closedOnMorning = false;
          break;
        default:
          break;
      }

      counterExit++;
      if (counterExit > 8) openCloseAt = "no data";
    }
  }
  return (isOpened ? `Abierto - ` : `Cerrado - `) + openCloseAt;
};

export const createPoint = (agency) => {
  const street = agency.address.streetName;
  const city = agency.address.city.name;

  const point = {
    is_santiago:
      agency.address.city.id.length === 5
        ? agency.address.city.id.startsWith("13")
          ? true
          : false
        : false,
    name: agency.agencyName,
    is_24_hours: agency.schedules.fullTime,
    point_type: {
      code: agency.typeAgency.code,
      description: agency.typeAgency.name,
    },
    distance: `${agency.distance
      .toFixed(1)
      .toString()
      .replace(/0+$/, "")
      .replace(/\.+$/, "")} km.`,
    address: `${street.charAt(0).toUpperCase()}${street
      .slice(1)
      .toLowerCase()} ${agency.address.streetNumber}, ${city
      .charAt(0)
      .toUpperCase()}${city.slice(1).toLowerCase()}`,

    phone: "Próximamente",
    // schedule1: calculateScheduleOld(getCurrentDate(), agency.open_hours),
    schedule: calculateSchedule(getCurrentDate(), agency.schedules.attentions), //"Lunes a Viernes de 9:00 a 18:00 hrs.", /FALTA ESTE
    coordenadas: {
      lat: agency.address.geolocation.latitude,
      lng: agency.address.geolocation.longitude,
    },
    services: {
      isPackageAdmission: agency.packageReception,
      isPackageDelivery: agency.pickupAvailability,
      isReturnOfPackages: false,
    },

    scheduleDetail: [
      {
        day: "Lunes",

        open:
          agency.schedules.attentions !== undefined &&
          isNotNill(agency.schedules.attentions[0]) &&
          agency.schedules.attentions[0].startTime,
        close:
          agency.schedules.attentions !== undefined &&
          isNotNill(agency.schedules.attentions[0]) &&
          agency.schedules.attentions[0].endTime,
        pickup:
          agency.schedules.cutHours !== undefined &&
          isNotNill(agency.schedules.cutHours[0]) &&
          agency.schedules.cutHours[0].hour,
      },
      {
        day: "Martes",
        open:
          agency.schedules.attentions !== undefined &&
          isNotNill(agency.schedules.attentions[1]) &&
          agency.schedules.attentions[1].startTime,
        close:
          agency.schedules.attentions !== undefined &&
          isNotNill(agency.schedules.attentions[1]) &&
          agency.schedules.attentions[1].endTime,
        pickup:
          agency.schedules.cutHours !== undefined &&
          isNotNill(agency.schedules.cutHours[1]) &&
          agency.schedules.cutHours[1].hour,
      },
      {
        day: "Miércoles",
        open:
          agency.schedules.attentions !== undefined &&
          isNotNill(agency.schedules.attentions[2]) &&
          agency.schedules.attentions[2].startTime,
        close:
          agency.schedules.attentions !== undefined &&
          isNotNill(agency.schedules.attentions[2]) &&
          agency.schedules.attentions[2].endTime,
        pickup:
          agency.schedules.cutHours !== undefined &&
          isNotNill(agency.schedules.cutHours[2]) &&
          agency.schedules.cutHours[2].hour,
      },
      {
        day: "Jueves",
        open:
          agency.schedules.attentions !== undefined &&
          isNotNill(agency.schedules.attentions[3]) &&
          agency.schedules.attentions[3].startTime,
        close:
          agency.schedules.attentions !== undefined &&
          isNotNill(agency.schedules.attentions[3]) &&
          agency.schedules.attentions[3].endTime,
        pickup:
          agency.schedules.cutHours !== undefined &&
          isNotNill(agency.schedules.cutHours[3]) &&
          agency.schedules.cutHours[3].hour,
      },
      {
        day: "Viernes",
        open:
          agency.schedules.attentions !== undefined &&
          isNotNill(agency.schedules.attentions[4]) &&
          agency.schedules.attentions[4].startTime,
        close:
          agency.schedules.attentions !== undefined &&
          isNotNill(agency.schedules.attentions[4]) &&
          agency.schedules.attentions[4].endTime,
        pickup:
          agency.schedules.cutHours !== undefined &&
          isNotNill(agency.schedules.cutHours[4]) &&
          agency.schedules.cutHours[4].hour,
      },
      {
        day: "Sábado",
        open:
          agency.schedules.attentions !== undefined &&
          isNotNill(agency.schedules.attentions[5]) &&
          agency.schedules.attentions[5].startTime,
        close:
          agency.schedules.attentions !== undefined &&
          isNotNill(agency.schedules.attentions[5]) &&
          agency.schedules.attentions[5].endTime,
        pickup:
          agency.schedules.cutHours !== undefined &&
          isNotNill(agency.schedules.cutHours[5]) &&
          agency.schedules.cutHours[5].hour,
      },
      {
        day: "Domingo",
        open:
          agency.schedules.attentions !== undefined &&
          isNotNill(agency.schedules.attentions[6]) &&
          agency.schedules.attentions[6].startTime,
        close:
          agency.schedules.attentions !== undefined &&
          isNotNill(agency.schedules.attentions[6]) &&
          agency.schedules.attentions[6].endTime,
        pickup:
          agency.schedules.cutHours !== undefined &&
          isNotNill(agency.schedules.cutHours[6]) &&
          agency.schedules.cutHours[6].hour,
      },
    ],
    dimensions: `Hasta ${agency.maximumPackageDimensions.length} x ${agency.maximumPackageDimensions.width} x ${agency.maximumPackageDimensions.height} cms.`,
    weight: `Hasta ${agency.maximumPackageDimensions.weight} kg.`,
  };

  return point;
};

export const getCurrentDate = () => {
  let newDate = new Date();

  return newDate;
};

export const getDistance = (point1, point2) => {
  let radians = Math.PI / 180;
  let earthRadius = 6371;
  let aux =
    0.5 -
    Math.cos((point2.lat - point1.lat) * radians) / 2 +
    (Math.cos(point1.lat * radians) *
      Math.cos(point2.lat * radians) *
      (1 - Math.cos((point2.lng - point1.lng) * radians))) /
      2;
  return earthRadius * 2 * Math.asin(Math.sqrt(aux));
};

export const sortAgenciesByDistance = (agencies) => {
  let sortedAgencies = agencies.sort((a, b) =>
    a.distance > b.distance ? 1 : -1
  );
  sortedAgencies = { agencies: sortedAgencies };
  return sortedAgencies;
};

export const getDistancesAndSortAgencies = (agencies, actualLocation) => {
  let agenciesWithDistances = agencies.map((agency) => {
    const agencyLocation = {
      lat: agency.address.geolocation.latitude,
      lng: agency.address.geolocation.longitude,
    };
    return {
      ...agency,
      distance: getDistance(actualLocation, agencyLocation),
    };
  });

  const sortedAgencies = sortAgenciesByDistance(agenciesWithDistances);

  return sortedAgencies;
};

export const isNotNill = (object) => {
  if (object && object !== "" && object) return true;
  return false;
};

export const formatPointToDispatch = (object) => {
  const newObject = {
    location: {
      geolocation: object.address.geolocation,
      country_name: 'Chile',
      state_name: object.address.state.name,
      state_id: object.address.state.id,
      city_name: object.address.city.name,
      city_id: object.address.city.id,
      street_name: object.address.streetName,
      street_number: object.address.streetNumber.toString(),
      other_info: object.address.reference,
    },
    status: object.status,
    agency_id: object.agencyId.toString(),
    agency_name: object.agencyName,
    package_reception: object.packageReception,
    pickup_availability: object.pickupAvailability,
    maximum_package_dimensions: object.maximumPackageDimensions,
    point_type: object.typeAgency,
    is_24_hours: object.schedules.fullTime,
    client_services: object.services,
    additional_services: object.additionalServices,
  };
  return newObject;
};